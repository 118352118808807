@import './media.scss';
@import 'spoton-lib/styles/color';

$mobile: '<36em';
$landscape: '<48em'; // mobile landscape
$tablet: '<64em';
$desktop-small: '<=80em';
$desktop-large: '>=1920px';

@mixin mq-mobile {
    @include media($mobile) {
        @content;
    }
}

@mixin mq-mobile-small {
    @include media('<=phone') {
        @content;
    }
}

@mixin mq-landscape {
    @include media($landscape) {
        @content;
    }
}

@mixin mq-tablet {
    @include media($tablet) {
        @content;
    }
}

@mixin mq-desktop-small {
    @include media($desktop-small) {
        @content;
    }
}

@mixin mq-desktop-large {
    @include media($desktop-large) {
        @content;
    }
}

@mixin mq-safari {
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
            /* Safari only */
            @content;
        }
    }
}

$shadow-color: rgba(53, 63, 94, 0.1);
$shadow-bottom: 0px 2px 4px $shadow-color;
$main-color: #1769ff;
$main-color-dark: #1254cc;
$red-color: #f73e3c;

@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin onhover {
    overflow: visible;
    white-space: normal;
    word-break: break-word;
}

@mixin container($mobile: 0, $height: 100%) {
    padding: 2.6rem 3.8rem;
    overflow-y: auto;
    width: 100%;
    height: $height;
    @if ($mobile == 1) {
        padding: 0;
    }
}

@mixin button {
    width: 100%;
    background-color: $main-color;
    &:disabled {
        @include btn-disabled;
    }
    &:hover,
    &:focus {
        background-color: $main-color-dark;
    }
}

@mixin btn-disabled {
    background-color: $main-color;
    color: white;
    opacity: 0.3;
}

@mixin footer {
    width: 100%;
    background: white;
    border-top: 2px solid $shadow-color;
    position: fixed;
    bottom: 0;
    padding: 1rem;
    button {
        @include button;
    }
}

@mixin image($width, $height) {
    width: $width;
    height: $height;
}

@mixin center($position: relative) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

@mixin cc-input {
    max-height: 4rem;
    line-height: 1;
    span {
        position: relative;
        top: 0.31rem;
    }
}

@mixin icon-btn($color: $main-color) {
    color: $color;
    background-color: transparent;
    &:hover,
    &:focus {
        background-color: transparent !important;
    }
}
