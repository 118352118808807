@import '../../../styles/common.scss';

.bagHeader {
    width: 100%;
    height: 4rem !important; // override antd style
    box-shadow: $shadow-bottom;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5rem;
    background: white !important; // override antd style

    .closeIcon {
        border: 0;
        height: 50px;
        @include icon-btn(#092a66);
    }
    .editButton {
        font-weight: 500;
        padding: 0 8px;
        @include icon-btn;
    }
    .editButtonRow {
        display: flex;
        align-items: center;
    }
}

@include mq-mobile {
    .bagHeader {
        height: 60px !important; // override antd style
        padding: 0 1rem 0 0.5rem;
        position: relative;

        .bagTitle {
            position: absolute;
            left: 3.25rem;
            font-size: 1.25rem;
        }
        .backIcon {
            height: 45px;
            border: 0;
            @include icon-btn;
        }
    }
}
