@import '../../../styles/common.scss';
@import 'spoton-lib/styles/color';

// override react-dates style
.SingleDatePicker {
    width: 100%;
    height: 2.75rem;
    margin-bottom: 1.875rem !important;

    & > div {
        height: 100%;
    }

    .SingleDatePickerInput {
        border-radius: 4px;
    }

    .SingleDatePickerInput,
    .DateInput {
        width: 100%;
        height: 100%;
        input {
            height: 100%;
        }
    }
}
