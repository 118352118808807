@import '../../styles/common.scss';

.layout .header {
    width: 100%;
    height: 4.5rem;
    padding: 0 3.75rem;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: $shadow-bottom;
    z-index: 200;
    line-height: 1.5;

    .logoContainer {
        display: flex;
        align-items: center;
    }

    .retailerLogo {
        margin-right: 1rem;
        height: 3rem;
        img {
            height: 100%;
        }
    }

    .poweredBy {
        height: 3rem;
        border-left: 1px solid #e5e7ec;
        padding-left: 1rem;
        padding-top: 0.75rem;
        img {
            width: 5rem;
        }
    }

    .bagButton {
        width: auto;
        padding-left: 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background-color: $main-color;
        cursor: pointer;

        .bagIcon {
            width: 24px;
            height: 24px;
            background: url(../../images/Icons/shopping-cart-white.svg) no-repeat center;
        }
        .bagLabel {
            font-weight: 500;
            font-size: 0.94rem;
            padding: 10px 14px;
            @include icon-btn(white);
        }
    }
}

@include mq-mobile {
    .layout .header {
        .bagButton {
            background-color: white;

            .bagIcon {
                background: url(../../images/Icons/shopping-cart.svg) no-repeat center;
            }
            .bagLabel {
                color: $main-color;
                font-weight: 600;
            }
        }

        .backIcon {
            border: 0;
            @include icon-btn;
        }
    }
}

@include mq-landscape {
    .layout .header {
        padding: 0 0.5rem;
        height: 60px;

        .heading {
            align-items: center;
        }
    }
}

@include mq-desktop-large {
    .bagIcon {
        zoom: 1.2;
        -moz-transform: scale(1.2);
        margin-top: -2px;
    }
}
