@import '../../styles/common.scss';
@import 'spoton-lib/styles/color';

.confirm {
    text-align: center;
    justify-content: center;
    position: relative;
    padding: 3rem !important;
    overflow: hidden;
    border-radius: 0.5rem !important;
    .title {
        margin-bottom: 1.5rem;
        font-style: normal;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
    .body {
        margin-bottom: 2rem;
        white-space: pre-line;
    }
    .input {
        width: 20.5rem;
        margin: auto;
        margin-top: -1rem;
        margin-bottom: 0.5rem;
    }
}

.ant-modal-header {
    border-bottom: 0;
    position: absolute;
    left: 0;
    top: 0;
}

.modalFooter {
    display: flex;
    justify-content: space-evenly;
    button {
        min-width: 7.5rem;
    }
    .confirmBtn {
        background-color: $main-color;
    }
    .cancelBtn {
        @include icon-btn;
    }
    .removeBtn {
        background-color: $red-color !important;
        border: 0 !important;
    }
}

.ReactModal__Overlay {
    z-index: 1100 !important; // more than ant-drawer
}

.promptPromo {
    width: 700px !important;
    padding-top: 224px !important;
    .modalFooter {
        button {
            width: 12rem;
        }
    }
}

@include mq-desktop-large {
    .promptPromo {
        width: 1000px !important;
        padding-top: 328px !important;
    }
}

@include mq-mobile {
    .confirm {
        position: fixed;
        top: 100%;
        border-radius: 8px 8px 0 0 !important;
        width: 100% !important;
        padding: 32px 24px !important;
        .title {
            font-weight: 600;
            line-height: 32px;
            margin-bottom: 16px;
        }
        .input {
            width: 100%;
        }
    }

    .promptPromo {
        padding-top: 140px !important;
        .modalFooter {
            button {
                width: unset;
            }
        }
    }
}
