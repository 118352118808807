@import '../../../styles/common.scss';

.timePicker {
    width: 100%;
    top: -0.75rem;
    input {
        height: 2.75rem;
    }
}

.meridian {
    height: 2.5rem !important;
    padding: 10px 12px !important;
    border: 0 !important;
    border-radius: 0 !important;
    position: absolute;
    right: 2px;
    top: 2px;
}

.timePickerRow {
    position: relative;
}