@import '../../styles/common.scss';

@mixin decorate($width, $minWidth, $margin) {
    width: $width;
    min-width: $minWidth;
    margin: $margin;
    margin-bottom: 0.5rem;
    input {
        height: 2.75rem;
    }
}

@mixin decorate-mobile($width) {
    width: $width;
    min-width: $width;
}

.checkoutContainer {
    @include container($height: 100%);

    .wrapper {
        width: calc(100% - 26rem);
    }

    .cardPreview {
        height: 64px;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        position: relative;
        & > div {
            height: 100%;
            align-items: center;
            p {
                margin: 0;
                margin-left: 16px;
            }
            .thumbnail {
                @include image($width: auto, $height: 100%);
            }
            .thumbnail:not(:first-child) {
                position: relative;
                margin-left: -32px;
            }
        }
        .button3 {
            font-size: 15px;
            font-weight: 500;
            padding: 0;
            @include icon-btn;
        }
    }

    .personalDetails {
        display: flex;
        flex-wrap: wrap;
    }

    .paymentDetails {
        position: relative;
    }

    .customInput {
        @include decorate(calc(30% - 1rem), 24rem, 0 1rem 0 0);
    }

    .expiry,
    .cvv {
        @include decorate(calc(15% - 0.5rem), 11.5rem, 0 1rem 1rem 0);
        @include cc-input;
        [class*='Error'] {
            white-space: nowrap;
        }
    }

    .number {
        @include decorate(calc(30% - 1rem), 24rem, 0 1rem 1rem 0);
        @include cc-input;
        margin-right: 55%;
        margin-bottom: 1.5rem;
        span {
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .ccHolderName {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        top: -0.25rem;
        .customInput {
            @include decorate(calc(15% - 0.5rem), 11.5rem, 0 1rem 0 0);
        }
        .ccInputMargin {
            @include decorate(calc(30% - 1rem), 24rem, 0 1rem 0 0);
        }
    }

    .postalCode {
        margin-top: -0.25rem;
        [class*='InputCondition'] {
            white-space: nowrap;
        }
    }

    .inputDesc {
        margin-bottom: 1rem;
    }
}

@mixin mobile-query {
    .customInput,
    .number {
        @include decorate-mobile(calc(100% - 1rem));
    }

    .expiry,
    .cvv,
    .postalCode {
        @include decorate-mobile(calc(50% - 1rem));
    }

    .number {
        margin-bottom: 12.5rem;
    }

    .ccHolderName {
        top: 5.25rem;
        width: 100%;
        .customInput {
            @include decorate-mobile(calc(100% - 1rem));
        }
    }

    .inputTitle {
        font-size: 1.06rem;
    }

    .footer {
        @include footer;
    }
}

@include mq-desktop-small {
    .checkoutContainer {
        .number {
            margin-bottom: 7rem;
        }

        .ccHolderName {
            top: 5.25rem;
            .ccInputMargin {
                display: none;
            }
        }

        .postalCode {
            margin-top: 1rem;
        }
    }
}

@include mq-tablet {
    .checkoutContainer {
        @include mobile-query;

        .expiry,
        .cvv,
        .postalCode {
            @include decorate-mobile(calc(100% - 1rem));
        }
    }
}

@include mq-landscape {
    .checkoutContainer {
        @include container($mobile: 1, $height: calc(100% - 76px));
        @include mobile-query;

        .wrapper {
            width: 100%;
            padding-left: 1rem;
        }
    }
}

@include mq-safari {
    .checkoutContainer {
        padding-bottom: 80px;
    }
}
