@import './styles/common.scss';

.loader {
    z-index: 100;
}
.loaderLeft {
    left: -26rem;
}

.backButton {
    position: relative;
    left: -12px;
    .backIcon {
        border: 0;
        @include icon-btn;
    }
    .button2 {
        border: 0;
        padding-left: 0;
        @include icon-btn;
    }
}

.customInput {
    line-height: 1.2;
    input,
    textarea {
        border-radius: 0.25rem;
    }
}

.textArea {
    textarea {
        resize: none !important;
        height: 4rem;
    }
    label {
        width: auto;
    }
}

.w100 {
    width: 100% !important;
}

.mb4 {
    margin-bottom: 0.25rem !important;
}
.mb8 {
    margin-bottom: 0.5rem !important;
}
.mb16 {
    margin-bottom: 1rem !important;
}
.mb24 {
    margin-bottom: 1.5rem !important;
}

.dNone {
    display: none !important;
}
.dBlock {
    display: block !important;
}
.dFlex {
    display: flex !important;
}

@include mq-desktop-large {
    .customInput {
        label {
            left: 13px;
            top: -37px;
        }
        input:focus ~ label,
        input:not([value='']) ~ label {
            top: -67px;
        }
        button {
            top: 15px;
        }
    }

    .textArea {
        label {
            top: 1.25rem;
        }
    }
}

@include mq-mobile {
    .pageTitle {
        line-height: 3 !important;
        font-size: 1.25rem !important;
        white-space: nowrap;
        position: fixed;
        top: 0;
        left: 3.25rem;
        z-index: 200;
    }
    .pageSubTitle {
        text-align: center;
        margin: 16px !important;
    }

    .dsmBlock {
        display: block !important;
    }
    .dsmNone {
        display: none !important;
    }
    .dsmFlex {
        display: flex !important;
    }
}
