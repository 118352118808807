.container404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 15px;
}

.fourZeroFour {
    font-size: 50px;
    font-weight: bold;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-shadow: 2px 5px grey;
}

.btnGoBack {
    background-color: #1769ff;
}

.btnGoBack:hover,
.btnGoBack:focus {
    background-color: #1254cc;
}
