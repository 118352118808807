@import '../../../../styles/common.scss';

.bagItemMobile {
    margin: 1rem;
    padding-bottom: 1rem;
    position: relative;

    .cardImage {
        width: 100%;
        border-radius: 0.25rem;
        &:not(:first-child) {
            margin-top: -3.5rem;
        }
    }

    .ant-badge-count {
        // override antd style
        min-width: 28px;
        height: 28px;
        border-radius: 50%;
        font-size: 15px;
        padding: 4px;
    }

    .cardInfo {
        padding-left: 1rem;
        .name {
            @include ellipsis;
        }
        .email,
        .date {
            @include ellipsis;
            color: #676b6c;
        }
    }

    .amount {
        @include ellipsis;
        text-align: center;
        margin-top: 0.5rem;
    }

    .icons {
        position: absolute;
        right: 0;
        bottom: 0;
        button {
            border: 0;
            @include icon-btn;
        }
    }
}
