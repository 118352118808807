@import '../../styles/common.scss';
@import 'spoton-lib/styles/color';

.orderCard {
    padding: 32px 24px;
    margin-top: 1.5rem;
    width: 100%;
    box-shadow: 0px 1px 2px rgba(10, 10, 11, 0.161);

    .ant-row {
        margin-bottom: 1rem;
    }
    .ant-row:last-child {
        margin-bottom: 0;
    }
    .imageBox {
        width: 100%;
    }
    .multiline {
        display: flex;
        flex-direction: column;

        span:not(:last-child) {
            margin-bottom: 4px;
        }
    }
    .message {
        p {
            @include onhover;
        }
    }
}

@mixin groupLabel {
    font-size: 0.81rem;
    margin-bottom: 4px;
    color: color('gray', '50');
}

.textGroup {
    @include ellipsis;
    p,
    span {
        font-weight: 600;
        @include ellipsis;
        &:hover {
            @include onhover;
        }
    }
    label {
        @include groupLabel;
    }
}

@include mq-mobile {
    .orderCard {
        margin: auto;
        margin-top: 0;
        // override spoton-lib style
        padding: 16px;
        width: 70%;

        .button3 {
            padding-left: 0;
            margin-top: 8px;
            font-size: 0.94rem;
            font-weight: 500;
            @include icon-btn;
        }
    }

    .textGroup {
        margin-top: 16px;
        p,
        span {
            font-weight: 500;
        }
    }
}
