@import '../../styles/common.scss';

.emptyBagText {
    text-align: center;
    position: relative;
    top: 60%;
}

.cardWrapper {
    max-height: calc(100% - 12.25rem);
    overflow-y: auto;

    .removeBtn {
        position: sticky;
        position: -webkit-sticky;
        bottom: 0;
        background-color: white;
        width: 100%;
        color: $red-color !important;
        font-size: 14px;
        letter-spacing: 0.56px;
    }
}
