@import '../../styles/common.scss';

.orderConfirmation {
    @include container($height: 100%);

    .homeButton {
        width: 16.5rem;
        background-color: $main-color;
        padding: 0.7rem;
        color: white;
    }

    .orderInfo {
        text-align: center;
        padding: 0 0.5rem 1.5rem;
    }

    .disclaimer {
        text-align: center;
        margin: 2rem 0;
        width: 70%;
        @include center;
    }
}

@include mq-mobile {
    .orderConfirmation {
        @include container($mobile: 1, $height: 100%);

        .homeButton {
            margin: 16px auto;
        }
    }
}
