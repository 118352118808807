body {
    margin: 0;
    font-family: 'Poppins', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

h2,
h3,
h4,
h5,
h6,
p,
button,
label,
span,
div {
    font-family: 'Poppins', sans-serif !important;
}

@media only screen and (min-width: 1920px) {
    html {
        font-size: 20px;
    }
}

@media only screen and (max-width: 575px) {
    body {
        position: fixed;
    }
}
