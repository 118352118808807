@import '../../styles/common.scss';

@mixin image-box($width, $height) {
    width: $width;
    height: $height;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    img:hover {
        border-radius: 8px;
        border: 2px solid $main-color;
        padding: 3px;
    }
}

.cardDesignContainer {
    @include container($height: 100%);

    .categories {
        width: 100%;
        overflow-x: auto;
        margin-top: 2.5rem;
        font-size: 0.94rem;
        [class*='Tab_wrapper'] {
            min-width: max-content;
            max-width: max-content;
        }
    }

    .banner {
        margin-bottom: 1.5rem;
        width: 90%;
    }
}

.cardDesigns {
    display: flex;
    flex-wrap: wrap;

    .imageBox {
        @include image-box(380px, 240px);
        margin-top: 40px;
        margin-right: 24px;
        .cardImage {
            @include image($width: 100%, $height: 100%);
        }
    }
}

@include mq-mobile {
    .cardDesignContainer {
        @include container($mobile: 1, $height: 100%);
        padding: 16px 0;

        .cardDesigns {
            justify-content: center;
            .imageBox {
                width: 248px;
                height: 158px;
                margin: 8px;
            }
        }

        .banner {
            margin-left: 5%;
            margin-bottom: 16px;
        }

        .categories {
            margin-top: 0;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .footer {
            @include footer;
        }
    }

    .hasFooter {
        height: calc(100% - 76px);
    }
}

@include mq-safari {
    .hasFooter {
        padding-bottom: 80px;
    }
}

@include mq-mobile-small {
    // iphone 5/SE
    .cardDesigns {
        .imageBox {
            width: 288px !important; // to avoid nesting
            height: 181px !important;
        }
    }
}
