@import '../../../styles/common.scss';

.bagFooter {
    border-top: 2px solid $shadow-color;
    bottom: 0px;
    position: absolute;
    background: white !important; // override antd style
    width: 100%;
    padding: 1rem;

    .totalRow {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        h5 {
            font-weight: 500;
        }
    }

    button {
        @include button;
        margin-top: 1.5rem;
    }
}
