@import '../../styles/common.scss';

@keyframes slide-left {
    to {
        @include image(31.25rem, 19.5rem);
        left: 3.8rem;
        top: 9.5rem;
    }
}

@keyframes slide-center {
    to {
        @include image(31.25rem, 19.5rem);
        left: calc(50% - 15.5rem);
        top: 9.5rem;
    }
}

@keyframes slide-top {
    to {
        @include image(19rem, 12rem);
        left: calc(50% - 9.5rem);
        top: 1.5rem;
    }
}

.cardPersonalize {
    @include container($height: 100%);
    padding-bottom: 20px;
    position: relative;

    .imageBox {
        @include image($width: 500px, $height: 450px);
        margin-bottom: 24px;
        border-radius: 8px;
        overflow: hidden;
    }
    .fakeImage {
        position: absolute;
        z-index: 100;
        animation: slide-left 1s 1;
    }
}

@include mq-desktop-small {
    .cardPersonalize {
        .imageBox {
            margin-left: auto;
            margin-right: auto;
        }
        .fakeImage {
            animation: slide-center 1s 1;
        }
    }
}

@include mq-desktop-large {
    .cardPersonalize {
        .imageBox {
            zoom: 1.3;
            -moz-transform: scale(1.3);
            -moz-transform-origin: 0 0;
        }
    }
}

@include mq-mobile {
    .cardPersonalize {
        @include container($mobile: 1, $height: 100%);

        .imageBox {
            margin-left: 0;
            zoom: 0.6;
            -moz-transform: scale(0.6);
            @include center;
        }
        .fakeImage {
            animation: slide-top 1s 1;
        }
    }
}
