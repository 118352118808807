@import '../../../styles/common.scss';

.formContainer {
    flex-wrap: wrap;
    width: 620px;
    justify-content: space-around;
    margin: auto;
    margin-top: 0;

    .customInput {
        height: 6rem;
        input {
            height: 2.75rem;
        }
        [class*='InputCondition'] {
            bottom: 0;
        }
    }

    .radioGroup {
        width: 100%;
        justify-content: space-around;
        .radioButtons {
            width: 44%;
            .radio {
                margin-right: 1.25rem;
                margin-bottom: 1.25rem;
            }
        }
        .radioMargin {
            width: 44%;
        }
    }

    .submitBtn {
        @include button;
        margin-top: 2.5rem;
    }

    .formLeft,
    .formRight {
        width: 44%;
        .inputLabelTop {
            text-align: right;
            color: #77819c;
        }
        .inputLabelBottom {
            color: #77819c;
        }
    }

    .formLeft {
        .textArea {
            margin-top: -1.25rem;
        }
    }

    .formRight {
        .inputLabelTop {
            margin-bottom: -1rem;
        }
        .customInput:first-child {
            margin-bottom: 0.25rem;
        }
    }
}

@include mq-mobile {
    .formContainer {
        padding: 16px;
        width: 100%;

        .radioGroup {
            .radioButtons {
                width: 100%;
            }
        }
        .formLeft {
            width: 100%;
        }
        .formRight {
            width: 100%;
            margin-top: 24px;
        }
    }
}

@include mq-desktop-large {
    .formContainer {
        width: 775px;
    }
}
