@import './common.scss';

#root > div {
    height: 100%;
}

.layout {
    height: 100%;
    .innerLayout {
        background-color: white;
        overflow-y: auto;
        overflow-x: hidden;
    }
    .errorMain {
        text-align: center;
        h2 {
            padding-top: 50px;
            width: 100%;
        }
    }
}

.bagContainer {
    // override antd style
    .ant-drawer-body {
        padding: 0;
        border-left: 1px solid $shadow-color;
    }
    .ant-drawer-content {
        overflow: hidden;
        border-radius: 0.5rem;
    }
    .ant-drawer-content-wrapper {
        width: 26rem !important;
    }
}

.bagEmpty {
    .ant-drawer-body {
        background: url(../images/Empty-cart.png) center center no-repeat;
    }
}

.belowHeader {
    height: calc(100% - 4.5rem);
    top: 4.55rem;
    width: 0 !important;
    // override antd style
    .ant-drawer-content-wrapper {
        box-shadow: none !important;
    }
}

@include mq-mobile {
    .layout {
        .errorMain {
            text-align: center;
            h2 {
                position: relative;
                margin-top: 60px;
                padding-top: 50px;
                width: 100%;
            }
        }
    }

    .bagContainer {
        // override antd style
        .ant-drawer-body {
            height: 100%;
        }
        .ant-drawer-content {
            border-radius: 0;
        }
        .ant-drawer-content-wrapper {
            width: 100% !important;
        }
    }
}
