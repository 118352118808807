@import '../../../styles/common.scss';

.priceInput {
    width: 100%;
    justify-content: space-around;
    margin-bottom: 1.5rem;

    .priceTags {
        width: calc(100% - 12.5rem);
        & > div {
            width: 100%;
        }
        & > div > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        button {
            border-radius: 4px;
            border: 1px solid #77819c;
            background-color: white;
            font-weight: 600;
            color: #77819c;
            width: 20%;
            height: 3rem;
        }
        button[class*='selected'] {
            color: $main-color;
            border: 1px solid $main-color;
            background-color: #e8f0ff;
        }
    }

    .customPrice {
        width: 8rem;
        position: relative;
        top: 0.5rem;
        input {
            height: 3rem;
        }
        [class*='InputCondition'] {
            position: absolute;
            left: -200%;
            top: 5.5rem;
        }
        button {
            top: 0.25rem;
        }
    }

    .inputLabelBottom {
        margin-top: -0.5rem;
        color: #77819c;
    }
}

@include mq-mobile {
    .priceInput {
        .priceTags {
            width: 100%;
            .inputLabelTop {
                font-size: 17px;
            }
        }

        .customPrice {
            width: 100%;
            [class*='InputCondition'] {
                left: 4.5rem;
                top: unset;
                bottom: -9px;
            }
        }
    }
}
